
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '產業新聞',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '產業新聞清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '產業新聞清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Title',
        title: '名稱',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'PublishedDate', title: '新聞日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd') },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      { field: 'ViewCount', title: '檢視次數', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` }
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('industryNews/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('industryNews/query') : undefined,
        save: model ? (params) => model.dispatch('industryNews/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: 'PublishedDate',
          title: '新聞日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        }, {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        {
          field: 'OfflineDate',
          title: '下線日期',
          span: 12,
          // titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          title: '關鍵字',
          span: 24,
          slots: { default: 'column-keyword' }
        },
        {
          field: 'LinkUrl', title: '鏈結網址', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入網址', clearable: true } },
        }
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        PublishedDate: [{ required: true }],
        LinkUrl: [{ message: '請輸入正確格式', type: 'string', max: 2000, pattern: new RegExp("(https?:\\/\\/[\\w-\\.]+(:\\d+)?(\\/[~\\w\\/\\.]*)?(\\?\\S*)?(#\\S*)?)"), required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("keyword/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 4)
          else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 4)
          return model!.dispatch("keyword/query", params)
        },
        insert: (value) => {
          var data = { entity: { Name: value.Name, Type: 4, Published: true } };
          console.log(data);
          return model!.dispatch("keyword/insert", data)
        },
      },
    };

    return {
      grid,
      selectorOptions,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("industryNews/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
      }
      callback();
    }
  }
})
